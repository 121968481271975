<script>
import Btn from "@/components/Form/Btn.vue";
import {isSameRouteWithQueryParams} from "@/plugins/functions";

export default {
    name: "FilterMobileLinks",
    methods: {isSameRouteWithQueryParams},
    components: {Btn},
    props: {
        links: {
            type: Array,
            required: true
        }
    },
}
</script>

<template>
    <div class="mobile-filter__links">
        <div class="mobile-filter__links-inner">
            <Btn
                v-for="(filterLink, key) in links"
                :key="key"
                :to="filterLink.url"
                class="mobile-filter__link"
                :class="{
                    'mobile-filter__link--active': isSameRouteWithQueryParams($route, filterLink.url),
                    'pl-0': !isSameRouteWithQueryParams($route, filterLink.url),
                    'pr-0': !isSameRouteWithQueryParams($route, filterLink.url)
                }"
            >
                {{ filterLink.title }}
            </Btn>
        </div>
    </div>
</template>

<style lang="scss">
.mobile-filter__links {
    width: 100%;
    position: relative;
    overflow: hidden;

    &:after {
        content: "";
        position: absolute;
        top: -1px;
        bottom: -1px;
        right: -1px;
        width: 16px;
        background-image: linear-gradient(to right, rgba(255, 255, 255, 0), var(--body));
    }
}

.mobile-filter__links-inner {
    display: flex;
    flex-wrap: nowrap;
    gap: $grid-gutter / 2;
    scrollbar-width: none;
    position: relative;
    overflow: auto;
}

.mobile-filter__link {
    &.v-btn.primary {
        background-color: transparent !important;
        color: var(--text-color);

        &:before {
            opacity: 0;
        }
    }

    &--active {
        &.v-btn.primary {
            background-color: #969696 !important;
            color: #fff;
        }
    }
}
</style>
